@import '~antd/dist/antd.css';

.localForm, .ant-input {
  background-color: var(--p150);
  color: var(--p800);
  border-radius: 4px;
  border: 1px solid var(--p150);
  width: 100%;
  transition: background-color 0.25s;
}

.localForm:focus {
  background-color: var(--p50);
  color: var(--p800);
  border: 1px solid var(--p150);
}

.inputReg, .ant-input {
  height: 54px;
  padding: 0 16px;
}